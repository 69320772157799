import { useState, useEffect } from 'react';
import { ChakraProvider, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Table, Tbody, Tr, Td, IconButton } from '@chakra-ui/react';
import { Card, Text, Heading, Stack, Box, CardBody } from '@chakra-ui/react';
import { Grid, GridItem, Flex, Image, Button, Input, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper } from '@chakra-ui/react';
import { collection, getDocs } from "firebase/firestore";
import { db } from './firebaseConfig'; // Import konfigurasi Firebase
import { DeleteIcon } from '@chakra-ui/icons'; // Import ikon hapus
import { jsPDF } from "jspdf"; // Import jsPDF untuk membuat laporan dalam bentuk PDF
import html2canvas from 'html2canvas'; // Import html2canvas


const Home = () => {
  const [produk, setProduk] = useState([]);
  const [kategori, setKategori] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // State untuk menyimpan nilai input pencarian
  const [selectedKategori, setSelectedKategori] = useState(''); // State untuk menyimpan kategori yang dipilih
  const [selectedProduk, setSelectedProduk] = useState(null); // State untuk menyimpan produk yang dipilih
  const [jumlah, setJumlah] = useState(1); // State untuk menyimpan jumlah produk
  const [keranjang, setKeranjang] = useState([]); // State untuk menyimpan keranjang belanja
  const [isCartOpen, setIsCartOpen] = useState(false); // State untuk modal keranjang

  useEffect(() => {
    const fetchDataProduk = async () => {
      const querySnapshot = await getDocs(collection(db, "produk"));
      const produkList = querySnapshot.docs.map(doc => doc.data());

      // Pilih 10 produk secara acak
      const shuffled = produkList.sort(() => 0.5 - Math.random());
      const selectedProduk = shuffled.slice(0, 10);

      setProduk(selectedProduk);
    };

    const fetchDataKategori = async () => {
      const querySnapshot = await getDocs(collection(db, "kategori"));
      const kategoriList = querySnapshot.docs.map(doc => doc.data());
      setKategori(kategoriList);
    };

    fetchDataProduk();
    fetchDataKategori();
  }, []);

  // const handleOpenModal = () => {
  //   setIsOpen(true);
  // };

  const handleCloseModal = () => {
    setIsOpen(false);
    setSelectedProduk(null); // Reset produk yang dipilih saat modal ditutup
    setJumlah(1); // Reset jumlah saat modal ditutup
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKategoriClick = async (kategori) => {
    setSelectedKategori(kategori);

    const querySnapshot = await getDocs(collection(db, "produk"));
    const produkList = querySnapshot.docs.map(doc => doc.data());
    const filteredProduk = produkList.filter(item => item.kategori === kategori);

    setProduk(filteredProduk);
  };

  const handleProdukClick = (produk) => {
    setSelectedProduk(produk);
    setIsOpen(true);
  };

  const handleJumlahChange = (value) => {
    setJumlah(value);
  };

  const handleAddToCart = () => {
    if (selectedProduk) {
      const newItem = {
        nama: selectedProduk.nama,
        berat:selectedProduk.berat,
        harga_eceran:selectedProduk.harga_eceran,
        harga_grosir1:selectedProduk.harga_grosir1,
        harga_grosir2:selectedProduk.harga_grosir2,
        harga_grosir3:selectedProduk.harga_grosir3,
        batas_eceran:selectedProduk.batas_eceran,
        batas_grosir1:selectedProduk.batas_grosir1,
        batas_grosir2:selectedProduk.batas_grosir2,
        batas_grosir3:selectedProduk.batas_grosir3,
        jumlah: jumlah
      };
      setKeranjang([...keranjang, newItem]);
      handleCloseModal();
    }
  };

  const handleOpenCart = () => {
    setIsCartOpen(true);
  };

  const handleCloseCart = () => {
    setIsCartOpen(false);
  };

  const handleRemoveFromCart = (index) => {
    const newKeranjang = [...keranjang];
    newKeranjang.splice(index, 1);
    setKeranjang(newKeranjang);
  };

  const handleGenerateReport = async () => {
    const doc = new jsPDF();
    let y = 10;

    // Fungsi untuk memformat angka dengan pemisah ribuan dan desimal
    const formatRupiah = (angka) => {
      return angka.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ',-';
    };

    // Tambahkan latar belakang hijau
    doc.setFillColor(72, 187, 120); // Warna hijau (green-300)
    doc.rect(0, 0, doc.internal.pageSize.getWidth(), 30, 'F'); // Mengisi latar belakang hijau di bagian atas

    // Tambahkan tulisan "JOSES ID" di tengah
    doc.setFontSize(20);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(255, 255, 255); // Warna teks putih
    doc.text("JOSES ID", doc.internal.pageSize.getWidth() / 2, 20, { align: "center" });
    y += 40; // Sesuaikan posisi y setelah menambahkan teks

    // Tambahkan judul di tengah kertas
    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(0, 0, 0); // Warna teks hitam
    doc.text("List Keranjang Anda", doc.internal.pageSize.getWidth() / 2, y, { align: "center" });

    // Tambahkan tanggal dan waktu pemesanan di bagian atas sebelah kanan
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    const formattedTime = currentDate.toLocaleTimeString();
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text(`Tanggal: ${formattedDate}`, doc.internal.pageSize.getWidth() - 10, y, { align: "right" });
    doc.text(`Waktu: ${formattedTime}`, doc.internal.pageSize.getWidth() - 10, y + 10, { align: "right" });
    y += 30;

    // Tambahkan header tabel
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("No", 10, y);
    doc.text("Nama Produk", 30, y);
    doc.text("Total Berat", 90, y);
    doc.text("Jumlah", 130, y);
    doc.text("Harga", 170, y);
    y += 10;

    // Tambahkan border header tabel
    doc.setLineWidth(0.5);
    doc.line(10, y - 5, 200, y - 5);

    // Tambahkan isian keranjang
    let totalPembayaran = 0;
    keranjang.forEach((item, index) => {
      const berat = Number(item.berat) * Number(item.jumlah);
      let harga = Number(item.harga_eceran);
      if (Number(item.jumlah) >= Number(item.batas_grosir3)) {
        harga = Number(item.harga_grosir3);
      } else if (Number(item.jumlah) >= Number(item.batas_grosir2)) {
        harga = Number(item.harga_grosir2);
      } else if (Number(item.jumlah) >= Number(item.batas_grosir1)) {
        harga = Number(item.harga_grosir1);
      }
      const totalHarga = harga * Number(item.jumlah);
      totalPembayaran += totalHarga;

      doc.setFont("helvetica", "normal");
      doc.text(`${index + 1}`, 10, y);
      doc.text(`${item.nama}`, 30, y);
      doc.text(`${berat} gram`, 90, y);
      doc.text(`${item.jumlah}`, 130, y);
      doc.text(`Rp ${formatRupiah(totalHarga)}`, 170, y); // Format harga
      y += 10;

      // Tambahkan border untuk setiap baris
      doc.line(10, y - 5, 200, y - 5);
    });

    // Tambahkan total pembayaran di bagian bawah
    y += 10;
    doc.setFont("helvetica", "bold");
    doc.text("Total Pembayaran:", 10, y);
    doc.text(`Rp ${formatRupiah(totalPembayaran)}`, 170, y); // Format total pembayaran

    // Simpan PDF
    // const pdfDataUri = doc.output('datauristring');

    // Buat elemen HTML yang berisi konten yang sama dengan PDF
    const reportElement = document.createElement('div');
    reportElement.style.width = '210mm'; // Lebar A4
    reportElement.style.backgroundColor = 'white';
    reportElement.style.padding = '20px';
    reportElement.innerHTML = `
      <div style="background-color: #48bb78; color: white; text-align: center; padding: 10px;">
        <h1>JOSES ID</h1>
      </div>
      <h2 style="text-align: center;">List Keranjang Anda</h2>
      <p style="text-align: right;">Tanggal: ${formattedDate}</p>
      <p style="text-align: right;">Waktu: ${formattedTime}</p>
      <table style="width: 100%; border-collapse: collapse; margin-top: 20px;">
        <thead>
          <tr>
            <th style="border: 1px solid black; padding: 5px;">No</th>
            <th style="border: 1px solid black; padding: 5px;">Nama Produk</th>
            <th style="border: 1px solid black; padding: 5px;">Total Berat</th>
            <th style="border: 1px solid black; padding: 5px;">Jumlah</th>
            <th style="border: 1px solid black; padding: 5px;">Harga</th>
          </tr>
        </thead>
        <tbody>
          ${keranjang.map((item, index) => {
            const berat = Number(item.berat) * Number(item.jumlah);
            let harga = Number(item.harga_eceran);
            if (Number(item.jumlah) >= Number(item.batas_grosir3)) {
              harga = Number(item.harga_grosir3);
            } else if (Number(item.jumlah) >= Number(item.batas_grosir2)) {
              harga = Number(item.harga_grosir2);
            } else if (Number(item.jumlah) >= Number(item.batas_grosir1)) {
              harga = Number(item.harga_grosir1);
            }
            const totalHarga = harga * Number(item.jumlah);
            return `
              <tr>
                <td style="border: 1px solid black; padding: 5px;">${index + 1}</td>
                <td style="border: 1px solid black; padding: 5px;">${item.nama}</td>
                <td style="border: 1px solid black; padding: 5px;">${berat} gram</td>
                <td style="border: 1px solid black; padding: 5px;">${item.jumlah}</td>
                <td style="border: 1px solid black; padding: 5px;">Rp ${formatRupiah(totalHarga)}</td>
              </tr>
            `;
          }).join('')}
        </tbody>
      </table>
      <h2><b>Total Pembayaran: Rp ${formatRupiah(totalPembayaran)}</b></h2>
    `;

    document.body.appendChild(reportElement);

    // Konversi elemen HTML ke gambar menggunakan html2canvas
    const canvas = await html2canvas(reportElement, {
      height: reportElement.scrollHeight // Batasi tinggi gambar sesuai dengan tinggi konten
    });
    const imgData = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = imgData;
    link.download = 'Laporan_Keranjang_Belanja.png';
    link.click();

    document.body.removeChild(reportElement);
  };

  const handleShopeeClick = () => {
    window.open('https://shopee.co.id/joses.id', '_blank');
  };

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/6285654050807', '_blank');
  };

  const filteredProduk = produk.filter(item => 
    item.nama.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (selectedKategori === '' || item.kategori === selectedKategori)
  );

  return (
    <ChakraProvider>
      <Flex direction="column" height="100vh">
        {/* Navbar */}
        <Flex
          as="header"
          align="center"
          justify="space-between"
          py="4"
          px={{ base: "4", md: "6", lg: "8" }} // Responsive padding
          bg="green.300"
          color="white"
          boxShadow="md"
        >
          {/* Logo dan Nama Aplikasi */}
          <Flex align="center">
            <img src="/img/icons/logo_josesid.png" alt="Logo" style={{ width: '100px', height: '100px' }} mr="2" />
          </Flex>

          {/* Pencarian */}
          <Input 
            type="text" 
            placeholder="Cari Produk..." 
            flex="1" 
            mx="4" 
            bg="white" 
            color="black" // Tambahkan warna teks hitam
            borderColor="green.700" // Tambahkan warna border hijau gelap
            _focus={{ borderColor: "green.900" }} // Warna border hijau gelap saat input aktif
            mr={{ base: "2", md: "4", lg: "6" }} 
            value={searchTerm} // Bind input value to searchTerm state
            onChange={handleSearchChange} // Update searchTerm state on input change
          />

          {/* Tombol Login */}
          {/* <Button onClick={handleOpenModal} colorScheme="green" variant="solid">
            Login
          </Button> */}

          <Modal isOpen={isOpen} onClose={handleCloseModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader size={{ base: 'xs', md: 'sm' }}>{selectedProduk ? selectedProduk.nama : 'Modal Header'}</ModalHeader>
              <ModalBody>
                {selectedProduk ? (
                  <Box textAlign="center"> {/* Tambahkan textAlign="center" */}
                    <Image 
                      src={selectedProduk.img} 
                      alt={selectedProduk.nama} 
                      borderRadius="md" 
                      mb="4" 
                      maxWidth="100%" 
                      maxHeight="200px" 
                      mx="auto" // Tambahkan mx="auto" untuk menempatkan gambar di tengah
                    />
                    <Text size={{ base: 'xs', md: 'sm' }}>Berat: {selectedProduk.berat * jumlah} gram</Text> {/* Berat dinamis */}
                    <NumberInput value={jumlah} min={1} onChange={handleJumlahChange} my="4">
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <Table variant="simple">
                      <Tbody>
                        <Tr>
                          <Td size={{ base: 'xs', md: 'sm' }}>Harga Eceran (min. {selectedProduk.batas_eceran} )</Td>
                          <Td size={{ base: 'xs', md: 'sm' }}>Rp {selectedProduk.harga_eceran} </Td>
                        </Tr>
                        <Tr>
                          <Td size={{ base: 'xs', md: 'sm' }}>Harga Grosir 1 (min. {selectedProduk.batas_grosir1} )</Td>
                          <Td size={{ base: 'xs', md: 'sm' }}>Rp {selectedProduk.harga_grosir1} </Td>
                        </Tr>
                        <Tr>
                          <Td size={{ base: 'xs', md: 'sm' }}>Harga Grosir 2 (min. {selectedProduk.batas_grosir2} )</Td>
                          <Td size={{ base: 'xs', md: 'sm' }}>Rp {selectedProduk.harga_grosir2} </Td>
                        </Tr>
                        <Tr>
                          <Td size={{ base: 'xs', md: 'sm' }}>Harga Grosir 3 (min. {selectedProduk.batas_grosir3} )</Td>
                          <Td size={{ base: 'xs', md: 'sm' }}>Rp {selectedProduk.harga_grosir3} </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>
                ) : (
                  <Text size={{ base: 'xs', md: 'sm' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam condimentum, ligula eu tincidunt consequat, urna erat dapibus tortor, sed eleifend turpis ipsum non tellus.</Text>
                )}
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="green" onClick={handleAddToCart} mr={3}>Tambah ke Keranjang</Button> {/* Tambahkan margin kanan */}
                <Button colorScheme="red" onClick={handleCloseModal}>
                  Batal
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Flex>

        <Flex flex="1">
          {/* Sidebar */}
          <Box
            as="aside"
            width="160px" // Lebar sidebar disempitkan
            bg="gray.100"
            p="4"
            boxShadow="md"
            height="100vh"
            overflowY="auto"
          >
            <Heading size={{ base: 'xs', md: 'sm' }} mb="4">Kategori Produk</Heading>
            <Stack spacing="3">
              {kategori.map((item, index) => (
                <Box 
                  key={index} // Tambahkan properti key yang unik
                  p="2" 
                  bg="white" 
                  borderRadius="md" 
                  boxShadow="sm"
                  cursor="pointer"
                  onClick={() => handleKategoriClick(item.nama)} // Set kategori yang dipilih saat diklik
                  _hover={{ bg: "gray.200" }} // Menambahkan efek hover
                  _active={{ bg: "gray.300" }} // Menambahkan efek saat aktif
                  width="100%" // Menyesuaikan lebar dengan lebar sidebar
                  m="0" // Menghilangkan margin kiri dan kanan
                >
                  <Text size={{ base: 'xs', md: 'sm' }}>{item.nama}</Text>
                </Box>
              ))}
            </Stack>
          </Box>

          {/* Konten Utama */}
          <Box flex="1" height="100vh" overflowY="auto"> {/* Menambahkan scrollbar jika konten terlalu panjang */}
            <Grid templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)", lg: "repeat(5, 1fr)" }} gap={1}>
              {filteredProduk.map(item => (
                <GridItem padding="1px" key={item.id} onClick={() => handleProdukClick(item)}> {/* Ubah padding untuk ukuran lebih kecil */}
                  <Card 
                    boxShadow="xl" 
                    borderWidth="0" 
                    borderRadius="md" 
                    width={{ base: "120px", md: "200px" }} // Adjust width for mobile
                    height={{ base: "200px", md: "250px" }} // Adjust height for mobile
                    display="flex" 
                    flexDirection="column"
                  >
                    <Image 
                      src={item.img} 
                      alt={item.nama} 
                      borderTopRadius="md" 
                      width="100%" 
                      height={{ base: "80px", md: "150px" }} // Adjust image height for mobile
                      objectFit="cover" 
                    />
                    <CardBody flex="1" display="flex" alignItems="center" justifyContent="center">
                      <Text size={{ base: 'xs', md: 'sm' }} textAlign="center">{item.nama}</Text> {/* Adjust font size */}
                    </CardBody>
                  </Card>
                </GridItem>
              ))}
            </Grid>
          </Box>
        </Flex>

        {/* Footer */}
        <Flex
          as="footer"
          align="center"
          justify="space-between"
          py="4"
          px={{ base: "4", md: "6", lg: "8" }} // Responsive padding
          bg="green.300"
          color="white"
          boxShadow="md"
        >
          <Flex align="center">
            <img 
              src="/img/icons/shopee.png" 
              alt="Shopee Logo" 
              style={{ width: '120px', height: '50px', cursor: 'pointer' }} 
              onClick={handleShopeeClick} 
            />
          </Flex>

          <Flex align="center">
            <img 
              src="/img/icons/whatsapp.png" 
              alt="WhatsApp Logo" 
              style={{ width: '120px', height: '50px', cursor: 'pointer' }} 
              onClick={handleWhatsAppClick} 
            />
          </Flex>

          <Text size={{ base: 'xs', md: 'sm' }}>&copy; 2024 JOSES ID</Text>
        </Flex>

        {/* Tombol Keranjang Mengambang */}
        <Button
          position="fixed"
          bottom="20px"
          right="20px"
          colorScheme="green"
          borderRadius="full"
          boxShadow="lg"
          onClick={handleOpenCart}
        >
          Keranjang ({keranjang.length})
        </Button>

        {/* Modal Keranjang */}
        <Modal isOpen={isCartOpen} onClose={handleCloseCart}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader size={{ base: 'xs', md: 'sm' }}>Keranjang Belanja</ModalHeader>
            <ModalBody>
              {keranjang.length > 0 ? (
                <Table variant="simple">
                  <Tbody>
                    {keranjang.map((item, index) => (
                      <Tr key={index}> {/* Tambahkan properti key yang unik */}
                        <Td size={{ base: 'xs', md: 'sm' }}>{item.nama}</Td>
                        <Td size={{ base: 'xs', md: 'sm' }}>{item.jumlah}</Td>
                        <Td>
                          <IconButton
                            aria-label="Hapus"
                            icon={<DeleteIcon />}
                            colorScheme="red"
                            onClick={() => handleRemoveFromCart(index)}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              ) : (
                <Text size={{ base: 'xs', md: 'sm' }}>Keranjang Anda kosong.</Text>
              )}
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="green" onClick={handleGenerateReport} mr={3}>
                Pesan
              </Button>
              <Button colorScheme="red" onClick={handleCloseCart}>
                Batal
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </ChakraProvider>
  );
}

export default Home;