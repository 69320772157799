import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import React from "react";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCRWBmflm2Tib2NknbvqVrB8nBegK2YArU",
    authDomain: "joses-indonesia.firebaseapp.com",
    databaseURL: "https://joses-indonesia.firebaseio.com",
    projectId: "joses-indonesia",
    storageBucket: "joses-indonesia.appspot.com",
    messagingSenderId: "240667790661",
    appId: "1:240667790661:web:f736ead8e75f46a5"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const FirebaseConfigComponent = () => {
    return <div>Firebase Config Loaded</div>;
};

export { db };
export default FirebaseConfigComponent;